import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts, useFeatureFlags, useLogoutOnError } from "@src/hooks";
import { useEffect } from "react";
import { useLingui } from "@lingui/react";
import { useQuery } from "@tanstack/react-query";
import { useShareMeritsApi } from "./useShareMeritsApi";

type QueryKey = readonly ["getShares"];

export const useGetSharesQueryKey = (): QueryKey => ["getShares"];

export const useGetShares = () => {
  const { api: shareMeritsApi, isReady } = useShareMeritsApi();
  const { showMemberAppShareMeritsFeature } = useFeatureFlags();
  const { onError } = useLogoutOnError();
  const queryKey = useGetSharesQueryKey();
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();

  const result = useQuery({
    enabled: isReady && showMemberAppShareMeritsFeature === true,
    queryFn: () => shareMeritsApi.getShares(),
    queryKey,
  });

  useEffect(() => {
    if (result.isError) {
      Log.error(`Error getting shared links`, { error: result.error });
      sendAlert({
        id: "getShares-error",
        text1: _(msg`Error getting shared links`),
        text2: String(result.error),
        type: "error",
      });
      onError(result.error);
    }
  }, [result.isError, result.error, onError, sendAlert, _]);

  return result;
};
